"use client";

import { classNames } from "@local/utils";
interface Props {
  date?: Date | string | null;
}
export const PreOrder = ({
  date
}: Props) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let displayDate = "today";
  let monthName = null;
  if (date) {
    const when = typeof date === "object" ? date : new Date(date);
    const now = new Date().getTime();

    // Check if time is not in the past
    if (now < when.getTime()) {
      monthName = months[when.getMonth()];
      displayDate = `${monthName} ${when.getDate()}, ${when.getFullYear()}`;
    }
  }

  /**
   * Made a change on Nov 20 to remove `ships today`
   */
  if (!monthName) return null;
  const label = monthName ? "Product in pre-order. Shipping date" : "Product in stock, ships";
  return <p className="text-sm" data-sentry-component="PreOrder" data-sentry-source-file="PreOrder.tsx">
      {label}{" "}
      <strong className={classNames(monthName ? "text-orange-800" : "text-green-800")}>
        {displayDate}
      </strong>
    </p>;
};